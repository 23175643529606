<template>
  <div>
    <!-- Filters -->
    <roles-filter
      :countries="countries"
      :check-permission="checkPermission"
      @filter-query="handleFilters"
    />
    <!--/ Filters -->
    <b-card class="mb-0">
      <div>
        <b-row>
          <b-col v-if="checkPermission(['CREATE_ROLES', 'ALLOW_ALL'])">
            <div class="d-flex justify-content-end">
              <create-rol-modal
                :countries="countries"
                :club-info="clubInfo"
                :check-permission="checkPermission"
                @successfully="getRoles"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-overlay
        :show="isLoading"
        opacity="1"
        :class="isLoading ? 'p-2 mb-1 mt-1' : ''"
      >
        <template #overlay>
          <div class="d-flex align-items-center justify-content-center mt-1">
            <b-spinner
              type="border"
              variant="primary"
            />
          </div>
          <div class="d-flex justify-content-center">
            <p class="pt-1">
              {{ $t('loading') }}
            </p>
          </div>
        </template>
        <b-table
          ref="refUserListTable"
          class="position-relative mt-1"
          responsive
          :fields="headerTableRol"
          :items="rolList"
          primary-key="id"
          show-empty
          :empty-text="$t('generic.noRecordsFound')"
        >
          <!-- Column: status -->
          <template #cell(status)="data">
            <div v-if="checkPermission(['CHANGE_STATUS_ROLES', 'ALLOW_ALL'])">
              <b-form-checkbox
                v-model="data.item.status"
                switch
                :value="1"
                :unchecked-value="0"
                @change="updateStatusRol(data.item.id)"
              />
            </div>
            <div v-else>
              <span v-if="data.item.status === 1">
                {{ $t('generic.active') }}
              </span>
              <span v-else-if="data.item.status === 0">
                {{ $t('generic.inactive') }}
              </span>
              <span v-else>
                {{ $t('generic.removed') }}
              </span>
            </div>
          </template>
          <!-- Column: club name -->
          <template #cell(club_name)="data">
            <span v-if="data.item.club != null">
              {{ data.item.club.club_name }}
            </span>
            <span v-else> N/A </span>
          </template>
          <!-- Column: created_at -->
          <template #cell(created_at)="data">
            <span v-if="data.item.created_at != null">
              {{ data.item.created_at | formatDate }}
            </span>
            <span v-else> N/A </span>
          </template>
          <!-- Column: permissions -->
          <template #cell(permissions)="data">
            <span v-if="checkPermission(['VIEW_PERMISSIONS', 'ALLOW_ALL'])">
              <b-button
                v-if="data.item.id != rolUserAuth.id && rolUserAuth.is_admin_club === data.item.is_admin_club"
                variant="primary"
                class="btn-icon rounded-circle"
                :style="colorPrimaryBtn"
                @click="
                  $router.push({
                    name: 'permissions',
                    params: { id: data.item.id },
                  })
                "
              >
                <feather-icon icon="LockIcon" />
              </b-button>
              <b-button
                v-else-if="data.item.id != rolUserAuth.id && rolUserAuth.is_admin_club === 1 || checkPermission(['VIEW_PERMISSIONS', 'ALLOW_ALL'])"
                variant="primary"
                :style="colorPrimaryBtn"
                class="btn-icon rounded-circle"
                @click="
                  $router.push({
                    name: 'permissions',
                    params: { id: data.item.id },
                  })
                "
              >
                <feather-icon icon="LockIcon" />
              </b-button>
              <span v-else>
                {{ $t('generic.noAccess') }}
              </span>
            </span>
            <span v-else>
              {{ $t('generic.noAccess') }}
            </span>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <edit-rol-modal
              :countries="countries"
              :club-info="clubInfo"
              :rol-data="data.item"
              :check-permission="checkPermission"
              :color-primary-btn="colorPrimaryBtn"
              @successfully="getRoles"
            />
          </template>
        </b-table>
      </b-overlay>
      <!-- Paginador -->
      <app-paginator
        :data-list="paginate"
        @pagination-data="changePaginate"
      />
      <!-- End paginador -->
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import checkPermission from '@/auth/permissions'
import servicesRole from '@/services/roleService'
import servicesCountry from '@/services/countryService'
import AppPaginator from '../../components/app-paginator/AppPaginator.vue'
import RolesFilter from './FilterRoles.vue'
import CreateRolModal from './CreateRolModal.vue'
import EditRolModal from './EditRolModal.vue'

export default {
  components: {
    AppPaginator,
    RolesFilter,
    CreateRolModal,
    EditRolModal,
  },

  data() {
    return {
      isLoading: false,
      filters: {
        name: null,
        club_hash: null,
        country_hash: null,
        state_hash: null,
        status: null,
      },
      paginate: {
        from: 1,
        to: 15,
        total: 0,
      },
      rolList: [],
      countries: [],
    }
  },

  computed: {
    ...mapGetters({
      rolUserAuth: 'role',
      clubInfo: 'clubInfo',
      colorPrimaryBtn: 'colorPrimaryBtn',
    }),
    headerTableRol() {
      return [
        {
          key: 'name',
          label: this.$t('roles.rol'),
          sortable: true,
        },
        {
          key: 'club_name',
          label: this.$t('corporate.club'),
          sortable: true,
        },
        {
          key: 'created_at',
          label: this.$t('generic.register'),
          sortable: true,
        },
        {
          key: 'status',
          label: this.$t('generic.status'),
          sortable: false,
        },
        {
          key: 'permissions',
          label: this.$t('routes.managementRoute.permissions'),
          sortable: false,
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
        },
      ]
    },
  },

  watch: {
    '$i18n.locale': function () {
      this.getRoles()
      this.getCountries()
    },
    'paginate.from': function () {
      this.getRoles()
    },
    'paginate.to': function () {
      this.getRoles()
    },
    'filters.name': function (name) {
      if (name != null || name !== '') this.getRoles()
    },
    'filters.club_hash': function (clubHash) {
      if (clubHash != null || clubHash !== undefined) this.getRoles()
    },
    'filters.country_hash': function (countryHash) {
      if (countryHash != null || countryHash !== undefined) this.getRoles()
    },
    'filters.state_hash': function (stateHash) {
      if (stateHash != null || stateHash !== undefined) this.getRoles()
    },
    'filters.status': function (status) {
      if (status != null) this.getRoles()
    },
  },

  mounted() {
    if (this.checkPermission(['VIEW_ROLES'])) {
      if (!this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])) {
        this.filters.idClub = this.clubInfo.hash
      }
      this.getRoles()
      this.getCountries()
    } else {
      this.$router.push({ name: 'not-authorized' })
    }
  },

  methods: {
    checkPermission,
    getCountries() {
      servicesCountry.getCountries()
        .then(({ data }) => {
          this.countries = data.data.map(e => ({ id: e.hash, name: e.labelables[0].label }))
        }).catch(error => {
          this.responseCatch(error)
        })
    },
    getRolesFilterReset() {
      this.filters.name = null
      this.filters.club_hash = null
      this.filters.country_hash = null
      this.filters.state_hash = null
      this.filters.status = null
      this.paginate.from = 1
      this.paginate.to = 15
      if (!this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])) {
        this.filters.idClub = this.clubInfo.hash
      }
      this.getRoles()
    },
    handleFilters(filterSelect) {
      if (filterSelect) {
        this.filters.name = filterSelect.search
        this.filters.club_hash = filterSelect.idClub // || this.clubInfo.hash
        this.filters.country_hash = filterSelect.idCountry
        this.filters.state_hash = filterSelect.idState
        this.filters.status = filterSelect.status
      }
      this.getRoles()
    },
    changePaginate(paginateData) {
      this.paginate.to = paginateData.perPage
      this.paginate.from = paginateData.page
    },
    getRoles() {
      const params = {
        included: 'club',
        perPage: this.paginate.to,
        page: this.paginate.from,
      }

      this.isLoading = true
      servicesRole.getRoles(params, this.filters)
        .then(({ data }) => {
          this.paginate.total = data.total
          const list = data.data.map(e => {
            return {
              id: e.hash,
              allow_all: e.allow_all,
              club: e.club,
              club_id: e.club_hash,
              created_at: e.created_at,
              is_admin_club: e.is_admin_club,
              is_internal: e.club == null ? 1 : 0,
              name: e.name,
              status: (e.status ? 1 : 0),
            }
          })
          this.rolList = list
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          this.responseCatch(error)
        })
    },
    updateStatusRol(roleData) {
      servicesRole.statusRole(roleData).then(({ data }) => {
        this.getRoles()
      }).catch(error => {
        this.responseCatch(error)
      })
    },
  },
}
</script>
